import classnames from 'classnames/bind';

import { relativeTimeFromNow } from 'services/date';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useUsageStatistics } from 'organizations/hooks/useUsageStatistics';

import { CloudStorageUtilizationCard } from 'organizations/views/OrganizationUtilization/cards/CloudStorageUtilizationCard';
import { GatewaysUtilizationCard } from 'organizations/views/OrganizationUtilization/cards/GatewaysUtilizationCard';
import { LiveStreamsUtilizationCard } from 'organizations/views/OrganizationUtilization/cards/LiveStreamsUtilizationCard';
import { RecordedUtilizationCard } from 'organizations/views/OrganizationUtilization/cards/RecordedUtilizationCard';

import * as PageLayout from 'components/PageLayout';
import { Heading } from 'components/Heading/Heading';
import { Pill } from 'components/Pill/Pill';

import { OverviewDeployments } from './Deployments';
import { OverviewEvents } from './Events';
import { OverviewFooter } from './Footer';

import styles from './Overview.module.scss';

const c = classnames.bind(styles);

export function Overview() {
  const { data: usageStatistics } = useUsageStatistics();
  const { data: organization } = useCurrentOrganization();

  const lastUpdate = relativeTimeFromNow(usageStatistics?.updated_at);

  return (
    <PageLayout.Root className={c('root')}>
      <PageLayout.Container>
        <div className={c('wrap')}>
          <div className={c('dashboard')}>
            <div className={c('dashboard-cards')}>
              <div className={c('deployments', 'dashboard-card')}>
                <OverviewDeployments />
              </div>
              <div className={c('gateways', 'dashboard-card')}>
                <GatewaysUtilizationCard />
              </div>
              <div className={c('organization-name', 'dashboard-card')}>
                <Heading level={2}>
                  {organization?.name || 'Organization'} utilization
                </Heading>
              </div>
              <div className={c('cloud-storage', 'dashboard-card')}>
                <CloudStorageUtilizationCard />
              </div>
              <div className={c('recorded-analytics', 'dashboard-card')}>
                <RecordedUtilizationCard />
              </div>
              <div className={c('active-streams', 'dashboard-card')}>
                <LiveStreamsUtilizationCard showInfo />
              </div>
              <div className={c('time-tag')}>
                <Pill icon="rotate">Updated {lastUpdate}</Pill>
              </div>
            </div>
            <div className={c('events')}>
              <OverviewEvents />
            </div>
            <div className={c('footer')}>
              <OverviewFooter />
            </div>
          </div>
        </div>
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
