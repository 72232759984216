import React from 'react';
import classnames from 'classnames/bind';
import { LinkProps, useParams } from 'react-router-dom';

import { ApplicationParams } from 'application/types/application_params';

import { lastElement } from 'services/array';
import { useCurrentApplication } from 'application/hooks/useCurrentApplication';
import { useInfiniteEvents } from 'hooks/api/useInfiniteEvents';

import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import { EventsList } from 'events/components/List/List';

import styles from './Overview.module.scss';

const c = classnames.bind(styles);

const RECENT_EVENTS_LENGTH = 20;

export function OverviewEvents() {
  const { applicationID } = useParams<ApplicationParams>();
  const { data: application } = useCurrentApplication();
  const { data: events, isLoading } = useInfiniteEvents();
  const recentEvents = React.useMemo(
    () => events.slice(0, RECENT_EVENTS_LENGTH),
    [events]
  );

  const oldestEvent = lastElement(recentEvents);
  const eventsTarget: LinkProps['to'] = {
    pathname: `/applications/${applicationID}/deploy/events`,
  };

  if (oldestEvent) {
    const from = +new Date(oldestEvent.event_ts);
    const to = +Date.now();
    eventsTarget.search = `?from=${from}&to=${to}`;
  }

  return (
    <Card className={c('item', 'dashboard-events')}>
      <div className={c('is-scrollable')}>
        <header className={c('dashboard-events-header')}>
          <div className={c('dashboard-events-header-info')}>
            <Heading level="3" asChild>
              <h2>Recent events</h2>
            </Heading>
            <Text>{application?.name}</Text>
          </div>

          <Button size="xsmall" to={eventsTarget}>
            View all events
          </Button>
        </header>

        <EventsList
          className={c('dashboard-events-list')}
          events={recentEvents}
          isLoading={isLoading}
        />
      </div>
    </Card>
  );
}
