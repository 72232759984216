import React from 'react';
import classnames from 'classnames/bind';
import { Badge, MantineColor } from '@mantine/core';

import { DeploymentState } from 'types/deployment';
import { StreamState } from 'types/stream';

import { formatPretty } from 'services/string';

import { Icon, IconType } from 'components/Icon/Icon';

import styles from './StatusIndicator.module.scss';

const c = classnames.bind(styles);

export type FileStatus = 'streaming' | 'uploading';

export type SuccessStatus =
  | 'online'
  | 'running'
  | 'success'
  | 'valid'
  | DeploymentState
  | FileStatus;
export type NeutralStatus = 'info' | 'unknown' | 'offline';
export type ErrorStatus = 'error' | 'warning' | 'unauthorized' | 'invalid';
export type IntermediateStatus = 'stopping' | 'starting' | 'pending';

export type Status =
  | StreamState
  | SuccessStatus
  | NeutralStatus
  | IntermediateStatus
  | ErrorStatus
  | 'progress';

export type StatusIndicatorProps = {
  className?: string;
  size?: 'small' | 'xsmall';
  status?: Status;
} & React.HTMLAttributes<HTMLSpanElement>;

const STATUS_COLOR_MAP: Record<Status, MantineColor> = {
  unknown: 'gray',
  unauthorized: 'gray',

  online: 'green',
  success: 'green',
  running: 'green',
  valid: 'green',

  deploying: 'blue',
  progress: 'blue',
  streaming: 'blue',
  uploading: 'blue',
  info: 'blue',

  starting: 'yellow',
  stopping: 'yellow',
  stopped: 'yellow',
  warning: 'yellow',
  pending: 'yellow',

  restart_waiting: 'blue',

  error: 'red',
  interrupted: 'red',
  offline: 'red',
  invalid: 'red',
};

const STATUS_ICON_MAP: Record<string, IconType> = {
  deploying: 'running',
  streaming: 'running',
  uploading: 'running',
  stopping: 'running',
  starting: 'running',
  pending: 'running',
  restart_waiting: 'running',
  progress: 'running',
  running: 'check',
  success: 'check',
  error: 'cancel',
  offline: 'cancel',
  interrupted: 'cancel',
  valid: 'check',
  invalid: 'danger',
};

// States with manual formatting
export const STATE_FORMATTED: Partial<Record<Status, string>> = {
  restart_waiting: 'Waiting to restart',
};

const SIZE_TO_MANTINE = {
  small: 'sm',
  xsmall: 'xs',
};

export function StatusIndicator({
  className,
  status,
  size,
  ...props
}: StatusIndicatorProps) {
  if (!status) {
    return null;
  }

  const color = STATUS_COLOR_MAP[status];
  const icon = STATUS_ICON_MAP[status] || (status as IconType);
  const label = STATE_FORMATTED[status] ?? formatPretty(status);
  const mantineSize = size ? SIZE_TO_MANTINE[size] : undefined;

  if (!size) {
    return (
      <Badge
        {...props}
        className={c('indicator', `status-${status}`, className, size)}
        aria-label={status}
        color={color}
        leftSection={<Icon className={c('icon')} name={icon} unstyled />}
        size={mantineSize}
      >
        {label}
      </Badge>
    );
  }

  return (
    <Badge
      {...props}
      className={c('indicator', `status-${status}`, className, size)}
      aria-label={label}
      color={color}
      style={{ display: 'inline-flex' }}
      variant={color === 'red' || color === 'green' ? 'filled' : 'light'}
      size={mantineSize}
      circle
    >
      <Icon className={c('icon')} name={icon} unstyled />
    </Badge>
  );
}
